$light-color: #fff;
$dark-grey: #555;
$light-grey: #F7F7F7;
$dark-color: #45C3CC;
$header-color: #45C3CC;
$light-text-color: $light-color;
$footer-color: #37A179;
$navy-dark-color: #1897A0;
$body-background-color: $light-grey;
$light-border-color: #4A446A;
$dark-blue: #45C3CC;
$light-blue: #33B0B9;
$main-blue: #0B434A;
$main-blue-hover: $dark-color;

// --------- new color scheme naming
$white: #FFF;
$black-700: #3A3A3A;
$black: #232323;


$gray-50: #F2F2F2;
$gray-100: #E6E6E6;
$gray-200: #AEAEAE;
$gray-600: #646464;
$gray-800: #242424;
$gray-900: #1A1A1A;

$blue-100: #FFF6F6;
$blue-400: #FFE0E0;
$blue-500: #FFD2D2;

$dark-blue-500: #455B7B;

@mixin gradient($from-color, $to-color) {
  background-color: mix($from-color, $to-color); /* Fallback */
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from-color), to($to-color));
  background-image: -webkit-linear-gradient(left, $from-color, $to-color);
  background-image:    -moz-linear-gradient(left, $from-color, $to-color);
  background-image:     -ms-linear-gradient(left, $from-color, $to-color);
  background-image:      -o-linear-gradient(left, $from-color, $to-color);
  -ms-filter:	"progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$from-color}', EndColorStr='#{$to-color}')";
}

@mixin title_divider {
  height: .3rem;
  width: 10rem;
  border-radius: .5rem;
  @include gradient($dark-blue, $main-blue-hover);
}

@mixin default-border {
  &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 0 0.2rem rgba(69, 195, 204, 0.5) !important;
  }
}
