@import './variables';

body {
  height: 100%;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  font-family: 'Work Sans', sans-serif;
  padding-top: 5rem;
}

@include media-breakpoint-down(xs) {
  body.bright-layout {
    padding-top: 5rem;
  }
  header.bright-theme {
    height: 5rem;
  }
}

header {
  background-color: $header-color;
  height: 3.2rem;
  color: $light-text-color;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

  a {
    color: $light-text-color;

    &:hover {
      color: #FFC400;
      text-decoration: none;
    }

    &.active {
      color: #FFC400;
    }
  }

  .logo {
    width: 10rem;
  }
  &.bright-theme {
    background-color: $light-color;
    height: auto;

    nav {
      line-height: 80px;

      .navbar-toggler {
        color: #333;
      }

      @include media-breakpoint-down(md) {
        .navbar-collapse {
          margin-left: -15px;
          margin-right: -15px;
        }
      }

      ul.navbar-nav {
        background-color: $light-color;

        li {
          font-weight: 500;
          font-size: 16px;
          text-transform: none;

          a {
            color: #333;
            border-bottom: 2px solid $light-color;
            margin-top: -2px;
            display: block;

            &:hover {
              color: #333;
              text-decoration: none;
              border-color: $main-blue; 
            }

            &.active {
              border-color: $main-blue;
            }
          }
        }

        @include media-breakpoint-down(md) {
          li {
            font-weight: 400;
            font-size: 18px;
            text-align: center;
            
            a {
              border-top: 1px solid #F0F0F0;
              border-bottom: 1px;
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}

footer {
  background-color: $footer-color;
  color: $light-text-color;

  a {
    color: $light-color;
    text-decoration: none;
    font-style: normal;
  }

  h5 {
    text-transform: uppercase;
    font-size: 1.3rem;
    display: inline-block;
    padding: 0 3rem .3rem 0;
  }

  h6 {
    font-size: 90%;
  }

  .logo {
    width: 17rem;

    @include media-breakpoint-down(sm) {
      width: 9.375rem;
    }
  }

  &.light-theme {
    background-color: $main-blue;
    font-size: 16px;
    line-height: 28px;

    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 28px;
      letter-spacing: 0.5em;
      background: $main-blue;
      display: table;
      margin: -14px auto 0px auto;
      padding: 0 25px;
    }

    h6 {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
    }

    a:hover {
      color: #fff;
    }

    .partners {
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      text-align: center;

      h4 {
        text-transform: uppercase;
      }
    }

    .copyrights {
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;

      a {
        text-decoration: none;
      }
    }

    @include media-breakpoint-down(md) {
      address {
        margin-bottom: 2rem;
      }
      .copyrights {
        text-align: center;
      }
      .logo {
        text-align: center;
      }
    }
  }
}

.copyrights {
  a {
    color: $light-color;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 500px) {
  .chat {
    overflow: scroll;
  }
}

@media only screen and (min-width: 576px) {
  body {
    padding-top: 5rem;
  }

  header {
    height: 5rem;

    nav {
      .navbar-nav {
        font-size: 80%;
      }
    }

    .logo {
      width: 15rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .navbar-nav {
    font-size: 80%;
  }
}
